var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container importTariffs" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("发票号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.invoiceCode,
                        callback: function($$v) {
                          _vm.invoiceCode = $$v
                        },
                        expression: "invoiceCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("合同号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.contractCode,
                        callback: function($$v) {
                          _vm.contractCode = $$v
                        },
                        expression: "contractCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("供应商名称：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.supplierName,
                        callback: function($$v) {
                          _vm.supplierName = $$v
                        },
                        expression: "supplierName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("创建时间：")]),
                    _c("el-date-picker", {
                      staticClass: "mr10",
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap",
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.subNavList, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        key: item.value,
                        attrs: { label: item.label, name: item.value }
                      },
                      [
                        _c("Table", {
                          attrs: {
                            tableList: _vm.tariffList,
                            parentData: _vm.parentData,
                            childData: _vm.childData,
                            tableFieldsList: _vm.fieldList,
                            tableLoading: _vm.tableLoading
                          },
                          on: {
                            editHandle: _vm.editHandle,
                            submitHandle: _vm.submitHandle,
                            deleteHandle: _vm.deleteHandle
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                ),
                !_vm.tariffList.length && !_vm.tableLoading
                  ? _c("p", { staticClass: "empty_tip" }, [_vm._v("暂无数据")])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: this.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "edit_part" }, [
            _c(
              "div",
              { staticClass: "clearfix mb10" },
              [
                _c(
                  "div",
                  { staticClass: "display_ib mr10" },
                  [
                    _vm._m(0),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10 mb10",
                      attrs: { placeholder: "请输入合同号" },
                      model: {
                        value: _vm.editList.contractCode,
                        callback: function($$v) {
                          _vm.$set(_vm.editList, "contractCode", $$v)
                        },
                        expression: "editList.contractCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10" },
                  [
                    _vm._m(1),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { filterable: "", placeholder: "请选择" },
                        on: {
                          change: function($event) {
                            return _vm.supplierNameChange($event, _vm.editList)
                          }
                        },
                        model: {
                          value: _vm.editList.supplierName,
                          callback: function($$v) {
                            _vm.$set(_vm.editList, "supplierName", $$v)
                          },
                          expression: "editList.supplierName"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择", value: "" }
                        }),
                        _vm._l(_vm.supplierList, function(item) {
                          return _c("el-option", {
                            key: item.supplierId,
                            attrs: {
                              label: item.supplierName,
                              value: item.supplierName
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10" },
                  [
                    _vm._m(2),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function($event) {
                            return _vm.receiverChange($event, _vm.editList)
                          }
                        },
                        model: {
                          value: _vm.editList.receiver,
                          callback: function($$v) {
                            _vm.$set(_vm.editList, "receiver", $$v)
                          },
                          expression: "editList.receiver"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择", value: "" }
                        }),
                        _vm._l(_vm.receiverList, function(item) {
                          return _c("el-option", {
                            key: item.dictItemValue,
                            attrs: {
                              label: item.dictItemName,
                              value: item.dictItemName
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10" },
                  [
                    _vm._m(3),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function($event) {
                            return _vm.paymentConditionSelect(
                              $event,
                              _vm.editList
                            )
                          }
                        },
                        model: {
                          value: _vm.editList.paymentCondition,
                          callback: function($$v) {
                            _vm.$set(_vm.editList, "paymentCondition", $$v)
                          },
                          expression: "editList.paymentCondition"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择", value: "" }
                        }),
                        _vm._l(_vm.paymentConditionList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "display_ib mr10" }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("合同预估关税总金额：")
                  ]),
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.editList.totalEstimateCustomsAdjustment
                          ? _vm.editList.totalEstimateCustomsAdjustment
                          : "0"
                      )
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "display_ib mr10" },
                  [
                    _vm._m(4),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { placeholder: "请选择承运商" },
                        on: {
                          change: function($event) {
                            return _vm.carrierNameSelect($event, _vm.editList)
                          }
                        },
                        model: {
                          value: _vm.editList.carrierName,
                          callback: function($$v) {
                            _vm.$set(_vm.editList, "carrierName", $$v)
                          },
                          expression: "editList.carrierName"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择承运商", value: "" }
                        }),
                        _vm._l(_vm.carrierNameList, function(item) {
                          return _c("el-option", {
                            key: item.dictItemValue,
                            attrs: {
                              label: item.dictItemName,
                              value: item.dictItemName
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10" },
                  [
                    _vm._m(5),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { placeholder: "请选择货物类型" },
                        on: {
                          change: function($event) {
                            return _vm.goodsTypeNameSelect($event, _vm.editList)
                          }
                        },
                        model: {
                          value: _vm.editList.goodsTypeName,
                          callback: function($$v) {
                            _vm.$set(_vm.editList, "goodsTypeName", $$v)
                          },
                          expression: "editList.goodsTypeName"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择货物类型", value: "" }
                        }),
                        _vm._l(_vm.goodsTypeList, function(item) {
                          return _c("el-option", {
                            key: item.dictItemValue,
                            attrs: {
                              label: item.dictItemName,
                              value: item.dictItemName
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("币种：原币种— ")
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function($event) {
                            return _vm.currencyChange($event, _vm.editList)
                          }
                        },
                        model: {
                          value: _vm.editList.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.editList, "currency", $$v)
                          },
                          expression: "editList.currency"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择", value: "" }
                        }),
                        _vm._l(_vm.currencyList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.name }
                          })
                        })
                      ],
                      2
                    ),
                    _c("span", { staticClass: "label" }, [
                      _vm._v("  本位币—人民币")
                    ])
                  ],
                  1
                ),
                _c("el-button", {
                  staticClass: "fr",
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                    circle: "",
                    title: "添加"
                  },
                  on: { click: _vm.addEditHandle }
                })
              ],
              1
            ),
            _c("div", { staticClass: "edit_wrap" }, [
              _c(
                "ul",
                { staticClass: "childlist" },
                _vm._l(_vm.editFieldList, function(item, index) {
                  return _c("li", { key: index, class: item.class }, [
                    item.isRules
                      ? _c("span", { staticClass: "red mr5" }, [_vm._v("*")])
                      : _vm._e(),
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n          "
                    )
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "allparentlist edit_parent_list clearfix" },
                [
                  _vm._l(_vm.editList.tradeTariffItems, function(item, index) {
                    return _c(
                      "ul",
                      { key: index, staticClass: "childcontentlist" },
                      [
                        _c(
                          "li",
                          { staticClass: "w10p" },
                          [
                            _c("el-input", {
                              staticClass: "w100p",
                              attrs: { size: "small", placeholder: "" },
                              model: {
                                value: item.goodsClass,
                                callback: function($$v) {
                                  _vm.$set(item, "goodsClass", $$v)
                                },
                                expression: "item.goodsClass"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "w10p" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "w100p",
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": _vm.invoiceCodeRemote,
                                  loading: _vm.loading
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.invoiceCodeChange(
                                      $event,
                                      index,
                                      item
                                    )
                                  }
                                },
                                model: {
                                  value: item.invoiceCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      item,
                                      "invoiceCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.invoiceCode"
                                }
                              },
                              _vm._l(_vm.invoiceCodeList, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c("li", { staticClass: "w5p" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { title: item.goodsNum }
                            },
                            [_vm._v(_vm._s(item.goodsNum))]
                          )
                        ]),
                        _c("li", { staticClass: "w10p" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { title: item.estimatedPayment }
                            },
                            [_vm._v(_vm._s(item.estimatedPayment))]
                          )
                        ]),
                        _c("li", { staticClass: "w10p" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { title: item.estimatedOtherPayment }
                            },
                            [_vm._v(_vm._s(item.estimatedOtherPayment))]
                          )
                        ]),
                        _c("li", { staticClass: "w10p" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { title: item.estimateFreight }
                            },
                            [_vm._v(_vm._s(item.estimateFreight))]
                          )
                        ]),
                        _c("li", { staticClass: "w10p" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { title: item.estimateCustoms }
                            },
                            [_vm._v(_vm._s(item.estimateCustoms))]
                          )
                        ]),
                        _c("li", { staticClass: "w10p" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { title: item.taxCoefficient }
                            },
                            [_vm._v(_vm._s(item.taxCoefficient))]
                          )
                        ]),
                        _c("li", { staticClass: "w10p" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { title: item.estimateTax }
                            },
                            [_vm._v(_vm._s(item.estimateTax))]
                          )
                        ]),
                        _c("li", { staticClass: "w10p" }, [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { title: item.estimateTotalAmount }
                            },
                            [_vm._v(_vm._s(item.estimateTotalAmount))]
                          )
                        ]),
                        _c("li", { staticClass: "w5p fr" }, [
                          _c("i", {
                            staticClass: "el-icon-remove",
                            attrs: { type: "primary", title: "删除" },
                            on: {
                              click: function($event) {
                                return _vm.deleteEditItemHandle(index, item)
                              }
                            }
                          })
                        ])
                      ]
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "edit_btn_wrap fr" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.cancelEditHandle }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.isSaveDisabled
                          },
                          on: { click: _vm.saveEditHandle }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.isSubmitDisabled
                          },
                          on: { click: _vm.submitEditHandle }
                        },
                        [_vm._v("提交审批")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ])
          ]),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.deleteDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDeleteHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.submitDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.submitDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定提交审批？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.submitDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSubmitHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("合同号：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("供应商名称：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("收款方：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("贸易条款：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("承运商：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "label" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("货物类型：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }